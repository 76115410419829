<ion-content slot="center">
  <div class="content-wrapper">
    <div class="login-container">
      <!-- <div class="translate-logo" >

        <ion-select labelPlacement="stacked"  [(ngModel)]="selectedLanguage" (ionChange)="onLanguageChange($event)" slot="end">
          <ion-icon slot="start" name="globe" aria-hidden="true"></ion-icon>
          <ion-select-option value="en">English</ion-select-option>
          <ion-select-option value="hi">Hindi</ion-select-option>
        </ion-select>
      </div> -->
      <!-- <div class="samhita-logo">
        <img
          src="../../../../assets/images/samhita-cgf.svg"
          alt="Samhita logo"
        />
      </div> -->
    </div>
    <form class="login-form-container">
      <div class="main-logo" (click)="moveToHome()">
        <img
        src="../../../../assets/icon-only.png"
        alt="logo"
        class="logo"
        />
      </div>
      <div class="login-title">
        <span class="skill">Skill</span><span class="mantra">Mantra</span>
      </div>
      <!-- <ion-radio-group
        [(ngModel)]="loginType"
        (ionChange)="onloginTypeChange($event)"
        [ngModelOptions]="{ standalone: true }"
        class="inline-radio-group"
      >
        <div class="radio-item">
          <ion-radio
            name="otp"
            slot="start"
            value="otp"
            class="custom-radio"
          ></ion-radio>
          <ion-label>Using OTP</ion-label>
        </div>

        <div class="radio-item">
          <ion-radio
            name="password"
            slot="start"
            value="username-password"
            class="custom-radio"
          ></ion-radio>
          <ion-label>Using Password</ion-label>
        </div>
      </ion-radio-group> -->

      <ng-container *ngIf="loginType === 'otp'">
        <app-mobile-otp></app-mobile-otp>
      </ng-container>

      <ng-container *ngIf="loginType === 'username-password'">
        <app-email-otp></app-email-otp>
      </ng-container>

      <div class="signup-text-container">
        <p class="signup-text" (click)="loginType = loginType === 'otp' ? 'username-password' : 'otp'">Login using {{loginType === 'otp' ? 'Password' : 'OTP'}}</p>
      <p class="signup-text" routerLink="/signup">Signup</p>
      </div>
      <!-- <p class="signup-text/">New user ? <a routerLink="/signup">Signup</a></p> -->
      <div class="powered-by">
        <strong>Powered by</strong>
        <img src="../../../../assets/images/cg-logo.png" alt="Samhita logo" />
      </div>
    </form>
  </div>
</ion-content>
