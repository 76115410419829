import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SESSION_STORAGE_KEYS } from '../../types/types';
import { USER_DETAILS } from '../../types/types';
import { SharedService } from '../../services/other/shared.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../services/other/toaster.service';
import { BecknService } from '../../services/api/beckn.service';
import { LoadingService } from '../../services/other/loading.service';
import { jobStatusResponse } from 'src/app/pages/mockdata';

@Component({
  selector: 'app-contact-provider',
  templateUrl: './contact-provider.component.html',
  styleUrls: ['./contact-provider.component.scss'],
})
export class ContactProviderComponent implements OnInit {
  myJobs: any[] = [];
  constructor(
    private modalController: ModalController,
    private sharedService: SharedService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private becknService: BecknService,
  ) {}
  helpForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    short_desc: new FormControl('', Validators.required),
    long_desc: new FormControl('', Validators.required),
    attachment: new FormControl(''),
    job: new FormControl('', Validators.required),
    reference: new FormControl('', Validators.required),
  });
  reference: string[] = [
    "ORDER",
    "PROVIDER",
    "FULFILMENT",
    "ITEM",
  ]
  userData: USER_DETAILS | null = null;
  ngOnInit(): void {
    this.userData =
      (this.sharedService.getDataFromSessionStorage(
        SESSION_STORAGE_KEYS.USER_INFO
      ) as USER_DETAILS) || null;
    this.helpForm.patchValue({
      name: this.userData?.profile.name || '',
      email: this.userData?.email || '',
    });
    if(this.userData) this.getMyJobs();
  }
  dismiss() {
    this.modalController.dismiss();
  }
  submitHelpForm() {
    console.log(this.helpForm.value);
    if (this.helpForm.invalid) {
      this.toastService.presentToast('Please fill all the fields', 'warning');
      return;
    }
    const payload = {
      bpp_id: this.helpForm.value.job.context.bpp_id,
      bpp_uri: this.helpForm.value.job.context.bpp_uri,
      transaction_id: this.helpForm.value.job.context.transaction_id,
      order_id: this.helpForm.value.job.order.id,
      short_desc: this.helpForm.value.short_desc,
      long_desc: this.helpForm.value.long_desc,
      additional_desc: {
        content_type: "text/plain",
        url: "https://buyerapp.com/additonal-details/desc.txt"
      },
      images: [
        this.helpForm.value.attachment
      ],
      ref: this.helpForm.value.reference
    };
    this.becknService.issueJob(payload).subscribe((res: any) => {
      console.log(res);
    }, (err) => {
      console.error(err);
      this.toastService.presentToast('Failed to submit help form', 'danger');
    });
    this.toastService.presentToast(
      'Your Request submitted successfully',
      'success'
    );
    this.becknService.issueCreated.next(true);
    this.dismiss();
  }

  async uploadAttachment(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;
    this.uploadTemporary();
    return;
    const s3URL = await this.sharedService.convertBinarytoS3(file); //!TODO
    this.helpForm.get('attachment')?.setValue(s3URL || null);
  }

  uploadTemporary() {
    this.toastService.presentToast(
      'Please upload the link of document,File upload not available currently',
      'warning'
    );
    return;
  }

  getMyJobs() {
    this.loadingService.presentLoading('Fetching job details...');
    this.becknService.getMyJobs().subscribe(
      (res: any) => {
        const response = res?.data;
        response.forEach((jobData: any) => {
          const resReqPayload =
            jobData.response_payload || jobData.request_payload;
          if (resReqPayload?.message?.order?.items.length) {
            resReqPayload.message.order.items.forEach(async (job: any) => {
              const order = resReqPayload.message.order;
              delete order.items;
              const modifiedJob = {
                order: order,
                jobItem: job,
                context: resReqPayload.context,
              };
              // await this.getTheJobStatus(
              //   modifiedJob.order,
              //   resReqPayload.context,
              //   order.id
              // );

              this.myJobs.push(modifiedJob);
            });
            this.loadingService.dismissLoading();
            console.log(this.myJobs);
          }
        });
      },
      (err) => {
        this.loadingService.dismissLoading();
        this.toastService.presentToast('Failed to fetch job details', 'danger');
      }
    );
  }
  async getTheJobStatus(order: any, context: any, orderId: any) {
    const payload = {
      bpp_id: context.bpp_id,
      bpp_uri: context.bpp_uri,
      transaction_id: context.transaction_id,
      order_id: orderId,
    };
    const res: any = await this.becknService.statusJob(payload);
    const byPassError =
      "Network error: HTTPSConnectionPool(host='job-hub.bpp.io', port=443): Max retries exceeded with url: /status (Caused by NameResolutionError";
    if (res.error.includes(byPassError)) {
      this.onStatusLongPull(context.transaction_id, order);
      return;
    }

    if (!res?.response_payload?.transaction_id) {
      this.loadingService.dismissLoading();
      this.toastService.presentToast('Failed to fetch job status', 'danger');
      return;
    }
    this.onStatusLongPull(context.transaction_id, order);
  }

  onStatusLongPull(transactionId: string, order: any) {
    try {
      let retryCount = 0;
      const maxRetries = 5;
      const fetchJobStatus = () => {
        setTimeout(() => {
          this.becknService.onStatusLongPull(transactionId).subscribe(
            (res: any) => {
              if (!res?.response_payload && retryCount < maxRetries) {
                retryCount++;
                fetchJobStatus();
                return;
              }
              this.loadingService.dismissLoading();
              order.status =
                res?.response_payload?.message?.order?.status ||
                jobStatusResponse.message.order.status;
              console.log('1');
            },
            (err: any) => {
              if (retryCount < maxRetries) {
                retryCount++;
                fetchJobStatus();
              } else {
                this.loadingService.dismissLoading();
              }
            }
          );
        }, 1000);
      };
      fetchJobStatus();
    } catch (error) {
      this.loadingService.dismissLoading();
    }
  }

  issueJob(job: any) {
    this.loadingService.presentLoading('Loading...');
    const payload = {
      bpp_id: job.context.bpp_id,
      bpp_uri: job.context.bpp_uri,
      transaction_id: job.context.transaction_id,
      order_id: job.order.id,
      cancellation_reason_id: '001',
    };
    this.becknService.issueJob(payload).subscribe((res: any) => {
      const byPassError =
        "Network error: HTTPSConnectionPool(host='job-hub.bpp.io', port=443): Max retries exceeded with url: /issue (Caused by NameResolutionError";
      if (res.error.includes(byPassError)) {
        this.onIssueLongPull(job.context.transaction_id, job.order);
        return;
      }
      if (!res.response_payload.transaction_id) {
        this.loadingService.dismissLoading();
        this.toastService.presentToast('Failed to issue job', 'danger');
        return;
      }
      this.onIssueLongPull(job.context.transaction_id, job.order);
    });
  }

  onIssueLongPull(transactionId: string, order: any) {
    let retryCount = 0;
    const maxRetries = 5;
    const fetchJobIssue = () => {
      setTimeout(() => {
        this.becknService.onIssueLongPull(transactionId).subscribe(
          (res: any) => {
            if (!res?.response_payload && retryCount < maxRetries) {
              retryCount++;
              fetchJobIssue();
              return;
            }
            this.loadingService.dismissLoading();
            order.status =
              res?.response_payload?.message?.order?.status || 'Cancelled';
            this.toastService.presentToast(
              'Updated successfully',
              'success'
            );
          },
          (err: any) => {
            if (retryCount < maxRetries) {
              retryCount++;
              fetchJobIssue();
            } else {
              this.loadingService.dismissLoading();
            }
          }
        );
      }, 1000);
    };
    fetchJobIssue();
  }
}
