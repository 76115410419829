import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";
import { ENDPOINTS } from "../../shared/endpoints/api-endpoints";
import { LoadingMessage } from "../../shared/types/types";
import { SESSION_STORAGE_KEYS } from "../../shared/types/types";
import { Router } from "@angular/router";
import { SharedService } from "src/app/shared/services/other/shared.service";
import { ToastService } from "src/app/shared/services/other/toaster.service";
import { LoadingService } from "src/app/shared/services/other/loading.service";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  private readonly endpointsToSkipLoading: string[] = [
    '/api/v1/skip-loading-example',
    '/api/v2/no-spinner',
    '/v1/ondc_client/transaction/',
    '/v1/ondc_client/search',
    '/v1/ondc_client/init',
    '/v1/ondc_client/select',
    '/v1/ondc_client/confirm',
    '/v1/ondc_client/status',
    '/v1/ondc_client/cancel',
    '/v1/ondc_client/update'
  ];

  private readonly endpointsToSkipToast: string[] = [
    '/api/v1/profile',
  ];
  private removeJsonContentType:string[]=[
    ENDPOINTS.AUS.GET_ADDRESS_BY_PINCODE,
    ]

  constructor(
    private sharedService: SharedService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN)?.access || null;
    const clonedRequest = this.addHeaders(req, token);

    const skipLoading = this.shouldSkipLoading(req.url);
    const skipToast = this.shouldSkipToast(req.url);

    if (!skipLoading) {
      const loadingMessage = this.getLoadingMessage(req);
      this.loadingService.showOverlayLoading(loadingMessage);
    }
    console.log(req.url)

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        this.checkIfAccessTokenExpired(error, req, next, skipToast);
        if (!skipToast) {
          this.handleError(error);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.loadingService.hideOverlayLoading();
      })
    );
  }

  private getLoadingMessage(req: HttpRequest<any>): string {
    const loadingMessages: LoadingMessage[] = [
      { endpoint: `${ENDPOINTS.SAMHITA_ONDC.GET_USER_DETAILS}`, method: 'GET', message: 'Fetching user details, please wait...' },
      { endpoint: `${ENDPOINTS.SAMHITA_ONDC.WORK_EXPERIENCES}`, method: 'POST', message: 'Fetching work experiences, please wait...' },
      { endpoint: `${ENDPOINTS.SAMHITA_ONDC.REQUEST_OTP}`, method: 'POST', message: 'Sending OTP, please wait...' },
      { endpoint: `${ENDPOINTS.SAMHITA_ONDC.EMAIL_PASSWORD_LOGIN}`, method: 'POST', message: 'Logging in, please wait...' },
      { endpoint: `${ENDPOINTS.SAMHITA_ONDC.PHONE_PASSWORD_LOGIN}`, method: 'POST', message: 'Logging in, please wait...' },
      { endpoint: `${ENDPOINTS.SAMHITA_ONDC.SIGNUP}`, method: 'POST', message: 'Signing up, please wait...' },
    ];

    const loadingMessageObj = loadingMessages.find(item => req.url.includes(item.endpoint) && req.method === item.method);
    return loadingMessageObj ? loadingMessageObj.message : 'Loading...';
  }

  private checkIfAccessTokenExpired(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler, skipToast: boolean): Observable<HttpEvent<any>> | void {
    const token_expired_message = 'Given token not valid for any token type';
    const user_not_found_message = 'Could not validate credentials';
    const message = error?.error?.errors || error?.error?.message || error?.error?.detail;

    if(message && message == user_not_found_message){
      this.sharedService.clearSessionStorage()
      this.toastService.presentToast('Session expired, please login again', 'danger');
      this.router.navigate(['/login']);
      return;
    }

    // if (message && message.includes(token_expired_message)) {
    //   const token = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN)?.refresh;
    //   if (token) {
    //     this.apiService.refreshToken({ refresh: token }).pipe(
    //       tap((res: any) => {
    //         this.sharedService.setDataToSessionStorage({ data: res, key: SESSION_STORAGE_KEYS.SESSION_TOKEN });
    //       }),
    //       switchMap(() => {
    //         const newToken = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN)?.access;
    //         const newClonedRequest = this.addHeaders(req, newToken);
    //         return next.handle(newClonedRequest);
    //       }),
    //       catchError((refreshError) => {
    //         if (!skipToast) {
    //           this.handleError(refreshError);
    //         }
    //         return throwError(() => refreshError);
    //       })
    //     ).subscribe();
    //   }
    // }
    return throwError(() => error);
  }

  private addHeaders(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    const headers = {
      ...(req.body instanceof FormData || this.removeJsonContentType.some(endpoint => req.url.includes(endpoint)) ? {} : { 'Content-Type': 'application/json' }),
      'Accept': 'application/json, text/plain, */*',
      ...(token ? { 'Authorization': `Bearer ${token}` } : {})
    };

    return req.clone({ setHeaders: headers });
  }

  private shouldSkipLoading(url: string): boolean {
    return this.endpointsToSkipLoading.some(endpoint => url.includes(endpoint));
  }

  private shouldSkipToast(url: string): boolean {
    return this.endpointsToSkipToast.some(endpoint => url.includes(endpoint));
  }

  private handleError(error: HttpErrorResponse): void {
    const message = error?.error?.detail || error?.error?.error || 'Something went wrong!';
    this.toastService.presentToast(message, 'danger');
  }

}
