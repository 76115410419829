import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from 'src/app/shared/services/other/shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { SESSION_STORAGE_KEYS, USER_DETAILS } from 'src/app/shared/types/types';
import { AppConstants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isMobile = true;
  userData: USER_DETAILS | null = null;
  private subscriptions: Subscription = new Subscription();
  menuItems: any[] = [];
  grantList: any[] = [];
  constructor(private sharedService: SharedService, private router: Router,private menu: MenuController) {}

  ngOnInit(): void {
    this.isMobile = this.sharedService.isMobile();
    this.loadUserData();
    const userSessionSubscription = this.sharedService.userSessionCreated.subscribe(() => this.loadUserData());
    this.subscriptions.add(userSessionSubscription);
  }

  private loadUserData(): void {
    this.userData = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.USER_INFO) as USER_DETAILS || null;
    if(this.userData){
      this.grantList = this.sharedService.getGrantList();
      this.menuItems = AppConstants.MENU_ITEMS      
    }
  }
  isGrantAllowed(permission: string[]){
    return permission.length === 0 || permission.some((permission: string) => this.grantList.includes(permission));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  navigateTo(route: string): void {
    // if(route === '/help'){
    //   this.sharedService.openHelpForm();
    //   return
    // }
    if(route === '/login'){
      this.signOut()
      return
    }
    this.menu.toggle();
    this.router.navigate([route]);
  }

  signOut(): void {       
    // this.menu.toggle();
    this.userData = null;
    this.sharedService.clearSessionStorage();
    this.router.navigate(['login']);
  }

  moveToProfile(){
    this.router.navigate(['profile']);
  }


  moveToCatalogueManagement(): void {
    this.menu.toggle();
    this.router.navigate(['catalogue-management']);
  }

  moveToSchemeConfiguration(): void {
    this.menu.toggle();
    this.router.navigate(['scheme-configuration']);
  }

  moveToUserManagement(): void {
    this.menu.toggle();
    this.router.navigate(['user-management']);
  }

  openMenu() {
    this.menu.toggle();
  }
}
