// app imports
import { environment } from "../../../environments/environment";

export class AppConstants {
  public static readonly REG_EXP = {
    GST: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
    PAN: "^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
    TAN: "^[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}$",
    AADHAAR: "^[0-9]{4}?[0-9]{4}?[0-9]{4}$",
    MASKED_AADHAAR: "^[0-9*]{4}?[0-9*]{4}?[0-9*]{4}$",
    ACCOUNT_NUMBER: "^[0-9]{9,18}$",
    IFSC_CODE: "^[A-Za-z]{4}[0-9]{7}$",
    VOTER_ID: "^[A-Z0-9]{10}$",
    DRIVING_LICENSE: "^DL[-\\s]?[0-9\\s-]{12,14}$",
    POSTAL_CODE: "^[0-9]{6}$",
    TEXT: /^[a-zA-Z ]*$/,
    TEXTWITHDOT: /^[a-zA-Z. ]*$/,
    ALPHANUMERIC: /^[A-Za-z. 0-9-]*$/,
    NUMBER: /^\d+$/,
    FRACTION: /^\d*\.?\d*$/,
    EMAIL: "^(?=.{6,40}$)[a-zA-Z0-9](?!.*[.]{2})[a-zA-Z0-9._-]*(?<![.])@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$",
    // EMAIL: '[a-z0-9._%+-]+@[a-z0-9.]+[.]+[com|org]{3,}|[cc]{2}$',
    MOBILE: "^[6-9]{1}[0-9]{9}$|^\\+91-?[6-9]{1}[0-9]{9}$",
    PASSWORD: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$",
    // Mobilewith91: /^([+]{0,2}(91))[6-9]\d{9}$/
    Mobilewith91: "^((\\+91-?)|0)?[6-9]{1}[0-9]{9}$",
    // ALPHA_NUMERIC: /^[a-zA-Z0-9\s]*$/,
    // ALPHA_NUMERIC_WITH_SPACE: /^[a-zA-Z0-9\s]*$/,
  };

  public static readonly HIDE_HEADER_ROUTES = ['/login', '/signup','/set-password']

  public static readonly MENU_ITEMS : { label: string, icon: string, route: string, permission: string[] }[] = [
    { label: 'Home', icon: 'home', route: '/home', permission: [] },
    { label: 'Profile', icon: 'person', route: '/profile', permission: [] },
    { label: 'My Jobs', icon: 'briefcase', route: '/my-jobs', permission: [] },
    { label: 'Help', icon: 'help-circle', route: '/igm', permission: [] },
  ]

  public static readonly PRIMARY_OCCUPATION_LIST: { label: string }[] = [
    { label: "Agriculture - Farmer (farming on own/leased-in land and leased-out farm land)" },
    { label: "Animal Husbandary (Dairy and Milk Products, Livestock rearing e.g. Poultry, Goat farming, Piggery, Fisheries, Sericulture, Bee-farming, etc.)" },
    { label: "Agriculture - Labourer (working on others farm land as labourer)" },
    { label: "Daily wage labourer (Non Agri e.g. MGNREGA, Construction workers and other labour)" },
    { label: "Logging and Gathering of Non Timber Forest Products (NTFP)" },
    { label: "Artisans & Craftsmanship (Weavers, Embroidery Artists, Painters, Pottery Artists, Woodcraft Artisans, etc.)" },
    { label: "Retail and Consumers Shop (Kirana, General Store, Clothing, Footwear, Cosmetics, Medical, Toys, Books, Stationery, Gift Shop, Courier Shop, Consumer durables and Electronics shop, etc.)" },
    { label: "Food and Beverages- fixed Shop (Tea & Paan, Restaurant, Cafe, Juice Shop, Sweets Shop etc.)" },
    { label: "Street Vendors- without fixed premises (Fruit, Flower, Vegetable, Food Cart and other vending activities)" },
    { label: "Manufacturing of Edible Items (Achaar, Papad, Masala, Flour Mills etc.)" },
    { label: "Manufacturing of Non-edible items (Textiles, Leather, Wood, Paper, Plastic, Rubber Products etc. )" },
    { label: "Tailoring (Own or employed)" },
    { label: "Edu-preneur (Tutoring and coaching services)" },
    { label: "Beauty and Wellness Services (Beautypreneurs (Own Premises), Masseur, Hairdresser, Barber, Beautician (service based freelancers or employees))" },
    { label: "Repairing Services (Mechanics, Home Appliances, Plumber, Electrician, Carpenter, Painter, Welder, Technicians, Machine Operators)" },
    { label: "Transport/Mobility Service Providers (Truck/Car/Auto/E-rickshaw Drivers)" },
    { label: "Government sector salaried workers" },
    { label: "Private/NGO sector salaried workers" },
    { label: "Banking and Financial Services (Business Facilitators/Correspondents)" },
    { label: "Village Level Entrepreneur (VLE)" },
    { label: "Sanitation and waste management (Sanitation workers, waste collectors and ragpickers)" },
    { label: "Online Delivery Workers (Zomato, Swiggy, Zepto, Blinkit, Dunzo etc.)" },
    { label: "Unemployed (currently studying/completed education or not involved in any primary occupation)" }
];

  public static readonly SECONDARY_OCCUPATION_LIST: { label: string }[] = [
    { label: "Agriculture - Farmer (farming on own/leased-in land and leased-out farm land)" },
    { label: "Animal Husbandary (Dairy and Milk Products, Livestock rearing e.g. Poultry, Goat farming, Piggery, Fisheries, Sericulture, Bee-farming, etc.)" },
    { label: "Agriculture - Labourer (working on others farm land as labourer)" },
    { label: "Daily wage labourer (Non Agri e.g. MGNREGA, Construction workers and other labour)" },
    { label: "Logging and Gathering of Non Timber Forest Products (NTFP)" },
    { label: "Artisans & Craftsmanship (Weavers, Embroidery Artists, Painters, Pottery Artists, Woodcraft Artisans, etc.)" },
    { label: "Retail and Consumers Shop (Kirana, General Store, Clothing, Footwear, Cosmetics, Medical, Toys, Books, Stationery, Gift Shop, Courier Shop, Consumer durables and Electronics shop, etc.)" },
    { label: "Food and Beverages- fixed Shop (Tea & Paan, Restaurant, Cafe, Juice Shop, Sweets Shop etc.)" },
    { label: "Street Vendors- without fixed premises (Fruit, Flower, Vegetable, Food Cart and other vending activities)" },
    { label: "Manufacturing of Edible Items (Achaar, Papad, Masala, Flour Mills etc.)" },
    { label: "Manufacturing of Non-edible items (Textiles, Leather, Wood, Paper, Plastic, Rubber Products etc. )" },
    { label: "Tailoring (Own or employed)" },
    { label: "Edu-preneur (Tutoring and coaching services)" },
    { label: "Beauty and Wellness Services (Beautypreneurs (Own Premises), Masseur, Hairdresser, Barber, Beautician (service based freelancers or employees))" },
    { label: "Repairing Services (Mechanics, Home Appliances, Plumber, Electrician, Carpenter, Painter, Welder, Technicians, Machine Operators)" },
    { label: "Transport/Mobility Service Providers (Truck/Car/Auto/E-rickshaw Drivers)" },
    { label: "Government sector salaried workers" },
    { label: "Private/NGO sector salaried workers" },
    { label: "Banking and Financial Services (Business Facilitators/Correspondents)" },
    { label: "Village Level Entrepreneur (VLE)" },
    { label: "Sanitation and waste management (Sanitation workers, waste collectors and ragpickers)" },
    { label: "Online Delivery Workers (Zomato, Swiggy, Zepto, Blinkit, Dunzo etc.)" },
    { label: "Not Involved in any secondary occupation" }
];

   public static readonly GENDER_LIST: { label: string }[] = [
    { label: "Female" },
    { label: "Male" },
    { label: "Other" },
];

  public static readonly EDUCATION_LIST: { label: string }[] = [
    { label: "Never attended formal schooling" },
    { label: "5th pass (Completed primary education) or below" },
    { label: "8th pass (Completed upper primary education) or below" },
    { label: "10th pass (Completed secondary education - Secondary School Certificate (SSC))" },
    { label: "12th pass (Completed higher secondary education - Higher Secondary School Certificate (HSC))" },
    { label: "Diploma (without a degree)" },
    { label: "Graduation and above (with a degree)" },

];

  public static readonly JOB_STATUS_LIST: { label: string }[] = [
    { label: "Employed" },
    { label: "Unemployed" },
    { label: "Self-employed" },
  ];

  public static readonly CURRENCY_LIST: { label: string }[] = [
    { label: "INR" },
    // { label: "USD" },
  ];

  public static readonly JOB_TYPES_LIST: { label: string }[] = [
    { label: "Full-time" },
    { label: "Part-time" },
    { label: "Internship" },
    { label: "Contract" },
    { label: "Temporary" },
  ];

  public static readonly CONTRY_LIST: { label: string }[] = [
    { label: "India" },
  ];

  public static readonly LANGUAGES_LIST: { label: string }[] = [
    { label: "English" },
    { label: "Hindi" },
    { label: "Malayalam" },
    { label: "Tamil" },
    { label: "Telugu" },
    { label: "Kannada" },
    { label: "Marathi" },
    { label: "Bengali" },
  ];

  public static readonly PROFICIENCY_LEVELS_LIST: { label: string }[] = [
    { label: "Beginner" },
    { label: "Intermediate" },
    { label: "Advanced" },
  ];
  public static readonly LOCATION_LIST: { label: string }[] = [
    { label: "Mumbai" },
    { label: "Delhi" },
    { label: "Bengaluru" },
    { label: "Chennai" },
    { label: "Hyderabad" },
    { label: "Kolkata" },
    { label: "Pune" },
    { label: "Ahmedabad" },
    { label: "Jaipur" },
    { label: "Surat" },
    { label: "Other" },
  ];
  

  public static readonly SALARY_LIST: { label: string }[] = [
    { label: "Below ₹50k" },
    { label: "₹50k - ₹100k" },
    { label: "Above ₹100k" },
  ];
}
