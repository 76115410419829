<div class="main-header">
  <ion-menu
    contentId="main-content"
    side="start"
    menuId="main-menu"
    [type]="'overlay'"
    *ngIf="!isMobile"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title> Menu </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>
       <ng-container *ngFor="let item of menuItems">
        
          <ion-item
          
          button
          (click)="navigateTo(item.route)"
        >
          <ion-icon slot="start" [name]="item.icon"></ion-icon>
          <ion-label>{{ item.label }}</ion-label>
        </ion-item>
        </ng-container>
       
      </ion-list>
    </ion-content>
  </ion-menu>
</div>

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="!isMobile && (userData?.phone || userData?.email)">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <div class="logo-title" [routerLink]="['']">
      <img
        src="../../../assets/icon-only.png"
        alt="Samhita Logo"
        class="logo"
      /> <span>Skill Mantra</span>
    </div>
    <ion-buttons [collapse]="true" slot="end">
      <ion-button class="user-info-button" *ngIf="userData?.phone || userData?.email" (click)="moveToProfile()">
        <ion-label class="username">{{ userData?.profile?.name || (userData?.email || '--').split('@')[0]}}</ion-label>
        <ion-avatar>
          <img
            class="avatar"
            alt="User Avatar"
            src="https://ionicframework.com/docs/img/demos/avatar.svg"
          />
        </ion-avatar>
      </ion-button>
      <ion-button class="user-info-button" *ngIf="!userData?.phone && !userData?.email" (click)="navigateTo('/login')">
        <ion-icon name="log-in-outline"></ion-icon>
        <ion-label class="ms-6">Login</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
