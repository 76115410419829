import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINTS } from '../../endpoints/api-endpoints';
import { retry } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BecknService {
  constructor(private http: HttpClient) {}

  issueCreated = new Subject<any>();

  searchJobs(searchQuery: string) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.SEARCH, { name: searchQuery });
  }

  selectJob(selectJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.SELECT, { ...selectJobPayload });
  }

  initJob(initJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.INIT, initJobPayload);
  }

  confirmJob(confirmJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.CONFIRM, confirmJobPayload);
  }

  statusJob(statusJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.STATUS, statusJobPayload).toPromise();
  }

  cancelJob(cancelJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.CANCEL, cancelJobPayload);
  }

  updateJob(updateJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.UPDATE, updateJobPayload);
  }

  issueJob(issueJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.ISSUE, issueJobPayload);
  }

  issueStatus(issueStatusPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.ISSUE_STATUS, issueStatusPayload).toPromise();
  }

  settleJob(settleJobPayload:any) {
    return this.http.post(ENDPOINTS.BECKN.JOBS.SETTLE, settleJobPayload);
  }

  getIssues() {
    return this.http.get(ENDPOINTS.BECKN.JOBS.GET_ISSUES);
  }

  getMyJobs() {
    return this.http.get(ENDPOINTS.BECKN.JOBS.GET_MY_JOBS);
  }

  workOpportunityTransaction(action: 'search' | 'select' | 'init' | 'confirm' | 'status' | 'cancel' | 'update' | 'issue' | 'settle', transactionId: string) {
    return this.http.get(`${ENDPOINTS.BECKN.JOBS.WORK_OPPORTUNITY_TRANSACTION}${transactionId}/${action}`)
  }

  onSearchLongPull(transactionId: string) {
    return this.workOpportunityTransaction('search', transactionId);
  }

  onSelectLongPull(transactionId: string) {
    return this.workOpportunityTransaction('select', transactionId);
  }

  onInitLongPull(transactionId: string) {
    return this.workOpportunityTransaction('init', transactionId);
  }

  onConfirmLongPull(transactionId: string) {
    return this.workOpportunityTransaction('confirm', transactionId);
  }

  onStatusLongPull(transactionId: string) {
    return this.workOpportunityTransaction('status', transactionId);
  }

  onCancelLongPull(transactionId: string) {
    return this.workOpportunityTransaction('cancel', transactionId);
  }

  onUpdateLongPull(transactionId: string) {
    return this.workOpportunityTransaction('update', transactionId);
  }

  onIssueLongPull(transactionId: string) {
    return this.workOpportunityTransaction('issue', transactionId);
  }

  onSettleLongPull(transactionId: string) {
    return this.workOpportunityTransaction('settle', transactionId);
  }
}
