import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINTS } from '../../endpoints/api-endpoints';
import { EMAIL_PASSWORD_LOGIN_PAYLOAD, OTP_LOGIN_PAYLOAD, PHONE_PASSWORD_LOGIN_PAYLOAD, SIGNUP_PAYLOAD, USER_DETAILS } from '../../types/types';

@Injectable({
  providedIn: 'root'
})
export class SamhitaOndcWoCService {

  constructor(private http:HttpClient) { }

  getUserDetails(){
    return this.http.get(ENDPOINTS.SAMHITA_ONDC.GET_USER_DETAILS).toPromise()
  }

  test(){}

  emailPasswordLogin(payload:EMAIL_PASSWORD_LOGIN_PAYLOAD){
    return this.http.post(ENDPOINTS.SAMHITA_ONDC.EMAIL_PASSWORD_LOGIN,payload)
  }

  phonePasswordLogin(payload:PHONE_PASSWORD_LOGIN_PAYLOAD){
    return this.http.post(ENDPOINTS.SAMHITA_ONDC.PHONE_PASSWORD_LOGIN,payload)
  }

  signUp(payload:SIGNUP_PAYLOAD){
    return this.http.post(ENDPOINTS.SAMHITA_ONDC.SIGNUP,payload)
  }

  OTPLogin(payload:OTP_LOGIN_PAYLOAD){
    return this.http.post(ENDPOINTS.SAMHITA_ONDC.OTP_LOGIN,payload)
  }

  requestOTP(payload:OTP_LOGIN_PAYLOAD){
    return this.http.post(ENDPOINTS.SAMHITA_ONDC.REQUEST_OTP,payload)
  }

  getS3SignedUrl(payload:any){
    return this.http.post(ENDPOINTS.SAMHITA_ONDC.GET_S3_SIGNED_URL,payload).toPromise()
  }

  getUserProfile(){
    return this.http.get(ENDPOINTS.SAMHITA_ONDC.USER_PROFILE)
  }

  createProfile(payload:USER_DETAILS){
    return this.http.post(ENDPOINTS.SAMHITA_ONDC.CREATE_PROFILE,payload)
  }

  updateProfile(payload:USER_DETAILS){
    return this.http.put(ENDPOINTS.SAMHITA_ONDC.USER_PROFILE,payload)
  }


}
