<ion-content>
    <div class="wrapper">
        <div class="header">
            <ion-button class="close-button" (click)="dismiss()" size="small" fill="clear" color="primary">
                <ion-icon name="close"></ion-icon>
            </ion-button>
            <h1 class="form-title">Help Form</h1>
            <p class="form-subtitle">How can we help you?</p>
        </div>
        <ion-list [formGroup]="helpForm">
            <ion-item>
                <ion-label position="stacked">Name</ion-label>
                <ion-input type="text" placeholder="Enter Name" required formControlName="name"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">Email</ion-label>
                <ion-input type="email" placeholder="Enter Email" required formControlName="email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">Select Job</ion-label>
                <ion-select placeholder="Select Job" formControlName="job" interface="popover">
                    <ion-select-option *ngFor="let job of myJobs" [value]="job">{{ job?.order?.quote?.breakup[0]?.title + '-' + job?.order?.quote?.breakup[0]?.id }}</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">Reference</ion-label>
                <ion-select placeholder="Select Reference" formControlName="reference" interface="popover">
                    <ion-select-option *ngFor="let ref of reference" [value]="ref">{{ ref }}</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">Short Description</ion-label>
                <ion-input type="text" placeholder="Enter Short Description" required formControlName="short_desc"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">Long Description</ion-label>
                <ion-textarea placeholder="Enter Long Description" rows="3" required formControlName="long_desc"></ion-textarea>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">Attachment</ion-label>
                <div class="d-flex align-center justify-between g-10 w-100">
                    <input #attachmentInput type="file" hidden (change)="uploadAttachment($event)" />
                    <ion-input class="space-input" placeholder="Upload Attachment"
                        formControlName="attachment"></ion-input>
                    <ion-icon size="large" name="cloud-upload" slot="end" (click)="uploadTemporary()"></ion-icon>
                </div>
            </ion-item>

        </ion-list>
        <div class="d-flex justify-center">
            <ion-button class="submit-button" color="primary" (click)="submitHelpForm()">Submit</ion-button>
        </div>
    </div>
</ion-content>