<ion-footer >
  <ion-tab-bar slot="bottom" *ngIf="isMobile && (userData?.phone || userData?.email)">
   <ng-container *ngFor="let item of menuItems">
    <ng-container *ngIf="isGrantAllowed(item.permission)">
      <ion-tab-button  [tab]="item.route" [selected]="item.route === selectedRoute" #tabButton (click)="navigateTo(item.route)">
        <ion-icon [name]="item.icon"></ion-icon>
        {{ item.label }}
      </ion-tab-button>
      </ng-container>
    </ng-container>
  </ion-tab-bar>
  <div class="powered-by" *ngIf="(isMobile && !userData?.phone && !userData?.email) || !isMobile">
    <span class="powered_by_text">Powered by</span>
    <img src="../../../assets/images/cg-logo.png" height="30px" alt="Samhita logo" />
  </div>
</ion-footer>