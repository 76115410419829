<ion-content class="ion-padding" slot="center">
  <div class="content-wrapper">
    <div class="signup-container">
      <div class="main-logo" (click)="moveToHome()">
        <img
        src="../../../../assets/icon-only.png"
        alt="logo"
        class="logo"
        />
      </div>
      <div class="login-title">
        <span class="skill">Skill</span><span class="mantra">Mantra</span>
      </div>
      <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">

        <ion-input class="space-input" fill="outline" labelPlacement="floating" label="Mobile Number" appPhoneNumber type="tel" formControlName="phone" required></ion-input>
        <div *ngIf="signupForm.get('phone')?.invalid && (signupForm.get('phone')?.touched || signupForm.get('phone')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="signupForm.get('phone')?.errors?.['required']">Mobile number is required.</small>
          <small class="error-message" *ngIf="signupForm.get('phone')?.errors?.['pattern']">Mobile number must be 10 digits.</small>
        </div>

        <ion-input class="space-input" fill="outline" labelPlacement="floating" label="Email" type="email" formControlName="email" required></ion-input>
        <div *ngIf="signupForm.get('email')?.invalid && (signupForm.get('email')?.touched || signupForm.get('email')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="signupForm.get('email')?.errors?.['required']">Email is required.</small>
          <small class="error-message" *ngIf="signupForm.get('email')?.errors?.['email']">Invalid email format.</small>
        </div>

        <ion-input  (ionInput)="checkPasswordStrength()" class="space-input" fill="outline" labelPlacement="floating" label="Password" type="password" formControlName="password" required></ion-input>
        <div *ngIf="signupForm.get('password')?.invalid && (signupForm.get('password')?.touched || signupForm.get('password')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="signupForm.get('password')?.errors?.['required']">Password is required.</small>
          <small class="error-message" *ngIf="signupForm.get('password')?.errors?.['minlength']">Password must be at least 6 characters long.</small>
        </div>

        <ion-input  (ionInput)="checkPasswordStrength()" class="space-input" fill="outline" labelPlacement="floating" label="Confirm Password" type="password" formControlName="confirmPassword" required></ion-input>
        <div *ngIf="signupForm.get('confirmPassword')?.invalid && (signupForm.get('confirmPassword')?.touched || signupForm.get('confirmPassword')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="signupForm.get('confirmPassword')?.errors?.['required']">Confirm password is required.</small>
          <small class="error-message" *ngIf="signupForm.get('confirmPassword')?.errors?.['minlength']">Confirm password must be at least 6 characters long.</small>
        </div>

        <div *ngIf="passwordStrength" class="password-strength-container">
          <p>Password Strength: {{ passwordStrength }}</p>
          <ul>
            <li>
              <span
                [ngClass]="{
                  checkmark: passwordCriteria.hasUpperCase,
                  wrongmark: !passwordCriteria.hasUpperCase
                }"
              >
                <ion-icon
                  *ngIf="passwordCriteria.hasUpperCase"
                  name="checkmark-circle"
                ></ion-icon>
                <ion-icon
                  *ngIf="!passwordCriteria.hasUpperCase"
                  name="close-circle"
                ></ion-icon>
                Uppercase Letter
              </span>
            </li>
            <li>
              <span
                [ngClass]="{
                  checkmark: passwordCriteria.hasLowerCase,
                  wrongmark: !passwordCriteria.hasLowerCase
                }"
              >
                <ion-icon
                  *ngIf="passwordCriteria.hasLowerCase"
                  name="checkmark-circle"
                ></ion-icon>
                <ion-icon
                  *ngIf="!passwordCriteria.hasLowerCase"
                  name="close-circle"
                ></ion-icon>
                Lowercase Letter
              </span>
            </li>
            <li>
              <span
                [ngClass]="{
                  checkmark: passwordCriteria.hasNumbers,
                  wrongmark: !passwordCriteria.hasNumbers
                }"
              >
                <ion-icon
                  *ngIf="passwordCriteria.hasNumbers"
                  name="checkmark-circle"
                ></ion-icon>
                <ion-icon
                  *ngIf="!passwordCriteria.hasNumbers"
                  name="close-circle"
                ></ion-icon>
                Number
              </span>
            </li>
            <li>
              <span
                [ngClass]="{
                  checkmark: passwordCriteria.hasSpecialChars,
                  wrongmark: !passwordCriteria.hasSpecialChars
                }"
              >
                <ion-icon
                  *ngIf="passwordCriteria.hasSpecialChars"
                  name="checkmark-circle"
                ></ion-icon>
                <ion-icon
                  *ngIf="!passwordCriteria.hasSpecialChars"
                  name="close-circle"
                ></ion-icon>
                Special Character
              </span>
            </li>
            <li>
              <span
                [ngClass]="{
                  checkmark: passwordCriteria.minLength,
                  wrongmark: !passwordCriteria.minLength
                }"
              >
                <ion-icon
                  *ngIf="passwordCriteria.minLength"
                  name="checkmark-circle"
                ></ion-icon>
                <ion-icon
                  *ngIf="!passwordCriteria.minLength"
                  name="close-circle"
                ></ion-icon>
                Minimum Length
              </span>
            </li>
            <li>
              <span
                [ngClass]="{
                  checkmark: passwordCriteria.passMatch,
                  wrongmark: !passwordCriteria.passMatch
                }"
              >
                <ion-icon
                  *ngIf="passwordCriteria.passMatch"
                  name="checkmark-circle"
                ></ion-icon>
                <ion-icon
                  *ngIf="!passwordCriteria.passMatch"
                  name="close-circle"
                ></ion-icon>
                Passwords Match
              </span>
            </li>
          </ul>
          <div class="strength-bar">
            <div
              [ngClass]="passwordStrengthClass"
              [style.width.%]="passwordStrengthPercentage"
            ></div>
          </div>
        </div>

        <ion-button expand="full" type="submit" class="signup-button" [disabled]="signupForm.invalid || !passwordCriteria.passMatch || passwordStrengthPercentage < 75">Sign Up</ion-button>
      </form>
      <p class="login-text">Already have an account? <a routerLink="/login">Login</a></p>
      <div class="powered-by">
        <strong>Powered by</strong>
        <img src="../../../../assets/images/cg-logo.png" alt="Samhita logo" />
      </div>
    </div>
  </div>
</ion-content>
