import { Component, OnInit } from '@angular/core';
import { SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';
import { SharedService } from 'src/app/shared/services/other/shared.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/shared/services/other/toaster.service';
import { SamhitaOndcWoCService } from 'src/app/shared/services/api/samhita-ondc-wo-c.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(
    private router: Router,
    private activateRouter: ActivatedRoute,
    private sharedService: SharedService,
    private toastService: ToastService,
    private samhitaOndcWoCService: SamhitaOndcWoCService
  ) { }

  ngOnInit(): void {
    this.validateToken();
  }

  private validateToken(): void {
    this.sharedService.clearSessionStorage();
    const { token } = this.activateRouter.snapshot.params;
    if (!token) {
      this.redirectToNotFound();
      return;
    }
    this.storeAndValidateTokens({ access: token });
  }

  private async storeAndValidateTokens(tokens: { access: string }): Promise<void> {
    try {
      this.sharedService.setDataToSessionStorage({ data: tokens, key: SESSION_STORAGE_KEYS.SESSION_TOKEN });
      const userData = await this.getTheUserDetails()
      if (userData) {
        this.sharedService.setDataToSessionStorage({ data: userData, key: SESSION_STORAGE_KEYS.USER_INFO });
        this.handleSuccessfulValidation(userData);
      } else {
        this.resetUserSession();
      }
    } catch (error) {
      this.resetUserSession();
    }
  }

  async getTheUserDetails() {
    try {
      return await this.samhitaOndcWoCService.getUserDetails()
    } catch (error) {
      return null
    }
  }

  private handleSuccessfulValidation(userData: any): void {
    this.sharedService.userSessionCreated.next(true);
    this.toastService.presentToast('User logged in successfully!', 'success', 'bottom');
    this.router.navigate(['/home']);
  }

  private resetUserSession(): void {
    this.sharedService.clearSessionStorage();
    this.redirectToNotFound();
  }

  private redirectToNotFound(): void {
    this.router.navigate(['/not-found']);
  }

}
